import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';

import App from './App';
import { getRelease } from './version';

try { require('#public/fonts.css'); }
catch { }

if (process.env.REACT_APP_SENTRY_DSN)
  Sentry.init({
    dsn : process.env.REACT_APP_SENTRY_DSN,
    release : getRelease(),
    integrations : [browserTracingIntegration()],
    tracesSampleRate : process.env.REACT_APP_SENTRY_SAMPLING_RATE,
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
