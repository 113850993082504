import React, { useCallback, useEffect, useState } from 'react';

import {
  LineItem,
  Assembly,
  Product,
  Fulfilment,
  Selection,
  DraftCustomOrder,
} from '#types';

import useOptions from '#hooks/useOptions';
import useProducts from '#hooks/useProducts';

import { settings } from '#materials';
import {
  CellElement,
  TableTextInputCell,
  TableSelectCell,
  TableCell,
} from '#materials/TableCell';

import { listRecords } from '#utils/data';
import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';
import { OrderFormMode } from '#components/orders/OrderForm';

const localeTableKeys = locale.keys.tables.selections;

export const SELECTION_TABLE_KEYS = {
  id : 'id',
  assembly : 'assembly',
  product : 'product',
  quantity : 'quantity',
  unit : 'unit',
  price : 'price',
  actions : 'actions',
} as const;
export type SelectionTableKey =
  typeof SELECTION_TABLE_KEYS[keyof typeof SELECTION_TABLE_KEYS];
export const defaultSelectionTableKeys = Object.values(SELECTION_TABLE_KEYS);

function generateAssemblyLabel(assembly : Assembly) { return assembly.name };
function generateProductLabel(product : Product) { return product.name };

function generateAssemblyKey(assembly : Assembly) {
  return `${assembly.id}`
}
function generateProductKey(product : Product) {
  return `${product.id}`
}

interface SelectionRowProps {
  lineItem : LineItem;
  selection : Selection;
  order? : DraftCustomOrder;
  mode? : OrderFormMode;
  disabled? : boolean;
  tableKeys? : SelectionTableKey[];
  setSelection? : (selection : Selection) => void;
  onUpdateFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
  generateActions? : (selection : Selection) => CellElement;
}

function SelectionRow({
  lineItem,
  selection,
  order,
  mode = 'edit',
  disabled = false,
  tableKeys = defaultSelectionTableKeys,
  setSelection,
  onUpdateFulfilment,
  generateActions,
} : SelectionRowProps) {
  const {
    assemblies : allAssemblies,
    getProductAssemblies,
    getAssemblyCollections,
    getCollectionProducts,
    getSelectionFulfilment,
    calculateSelectionPrice,
  } = useOptions();
  const { products : allProducts } = useProducts();

  const fulfilment = order
    ? getSelectionFulfilment(selection, order)
    : null;
  const lineItemProduct = listRecords(allProducts).find(
    product => product.id === lineItem.productId
  );

  const [assemblies, setAssemblies] = useState<Assembly[]>(lineItemProduct
    ? getProductAssemblies(lineItemProduct)
    : [],
  )
  const [assembly, setAssembly] = useState<Assembly | null>(
    listRecords(allAssemblies).find(a => a.id === selection?.assemblyId) ?? null
  );
  const [products, setProducts] = useState<Product[]>(assembly
      ? getAssemblyCollections(assembly)
        .map((c) => getCollectionProducts(c))
        .reduce(
          (acc, products) => [...acc, ...products],
          [],
        ) : listRecords(allProducts)
  );
  const [product, setProduct] = useState<Product | null>(
    listRecords(allProducts).find(p => (
      p.id === (mode === 'fulfilment'
        ? (fulfilment?.fulfilledProductId
          ?? fulfilment?.requestedProductId
          ?? selection?.productId)
        : selection?.productId)
    )) ?? null
  );
  const [quantity, setQuantity] = useState<number | null>(
    mode === 'fulfilment'
      ? (fulfilment?.fulfilledQty
        ?? fulfilment?.requestedQty
        ?? selection?.quantity
        ?? null)
      : selection?.quantity
  );

  const getAssemblyProducts = useCallback((assembly : Assembly) => {
    const collections = getAssemblyCollections(assembly);
    setProducts(collections.map((c) => getCollectionProducts(c)).reduce(
      (acc, products) => [...acc, ...products],
      [],
    ));
  }, [getAssemblyCollections, getCollectionProducts]);

  const handleProduct = useCallback(async (product : Product) => {
    if (!product || !product.id) return;
    setProduct(product);

    if (mode === 'fulfilment') {
      if (!fulfilment) return;
      await onUpdateFulfilment?.({
        ...fulfilment,
        fulfilledProductId : product.id,
      });
      return;
    }

    setSelection?.({ ...selection, productId : product.id });
  }, [selection, setSelection, mode, fulfilment, onUpdateFulfilment]);

  const handleAssembly = useCallback(async (assembly : Assembly) => {
    if (!assembly || !assembly.id) return;
    setAssembly(assembly);
    setSelection?.({ ...selection, assemblyId : assembly.id });
    getAssemblyProducts(assembly);
  }, [selection, setSelection, getAssemblyProducts]);

  const handleQuantity = useCallback((quantity : number | null) => {
    if (mode === 'fulfilment') setQuantity(quantity);
    setSelection?.({ ...selection, quantity : quantity ?? 1 });
  }, [selection, setSelection, mode]);

  const handleUpdateFulfilmentOnBlur = useCallback(async () => {
    if (
      onUpdateFulfilment
        && fulfilment
        && (quantity !== fulfilment?.fulfilledQty)
    ) {
      const updatedFulfilment = {
        ...fulfilment,
        fulfilledQty : quantity,
      }
      await onUpdateFulfilment(updatedFulfilment);
    }
  }, [
    fulfilment,
    quantity,
    onUpdateFulfilment,
  ]);

  const rowGenerator = useCallback(() => {
    const greyOut = (mode === 'fulfilment') && !fulfilment;
    const price = fulfilment?.unitPrice ?? calculateSelectionPrice(selection);

    return tableKeys.map((key) => {
      switch (key) {
        case SELECTION_TABLE_KEYS.id: return (
          <TableCell
            key={key}
            faded={greyOut}
            width={
              mode === 'fulfilment'
                ? settings.dimensions.small
                : settings.dimensions.xsmall
            }
          >
            { (selection?.id ?? 0) > 0
              ? `# ${selection?.id}` + ((fulfilment && mode === 'fulfilment')
                ? `-${fulfilment.id}`
                : '')
              : ''
            }
          </TableCell>
        );
        case SELECTION_TABLE_KEYS.assembly: return (
          disabled
            ? (
              <TableCell
                key={key}
                faded={greyOut}
                width={settings.dimensions.small}
              >
                { assembly?.name
                  ?? localize(localeTableKeys.defaults.assembly) }
              </TableCell>
            ) : (
              <TableSelectCell
                key={key}
                label={localize(localeTableKeys.headings.assembly)}
                selected={assembly}
                options={assemblies || []}
                onChange={handleAssembly}
                disabled={mode === 'fulfilment' ? true : false}
                labelGenerator={assembly => generateAssemblyLabel(assembly)}
                keyGenerator={generateAssemblyKey}
                width={settings.dimensions.medium}
              />
            )
        );
        case SELECTION_TABLE_KEYS.product: return (
          disabled
            ? (
              <TableCell
                key={key}
                faded={greyOut}
                width={settings.dimensions.medium}
              >
                { product?.name ?? localize(localeTableKeys.defaults.product) }
              </TableCell>
            ) : (
              <TableSelectCell
                key={key}
                label={localize(localeTableKeys.headings.product)}
                selected={product}
                options={products || []}
                onChange={handleProduct}
                labelGenerator={product => String(generateProductLabel(product))}
                keyGenerator={generateProductKey}
                width={settings.dimensions.medium}
                disabled={disabled || (mode === 'fulfilment' && !fulfilment)}
              />
            )
        );
        case SELECTION_TABLE_KEYS.quantity: return (
          <TableTextInputCell
            key={key}
            id={`${selection?.id}-quantity`}
            label={localize(localeTableKeys.headings.quantity)}
            value={quantity}
            onChange={handleQuantity}
            onBlur={handleUpdateFulfilmentOnBlur}
            inputType={settings.inputType.number}
            inputFormat={settings.inputFormat.float}
            disabled={disabled || (mode === 'fulfilment' && !fulfilment)}
            faded={greyOut}
            alignment={settings.alignments.right}
            width={settings.dimensions.xsmall}
          />
        );
        case SELECTION_TABLE_KEYS.unit:
          return (<TableCell key={key} faded={greyOut}>
            { price ? `(× ${formatCurrency(price)})` : ''}
          </TableCell>)
        case SELECTION_TABLE_KEYS.price:
          const qty = selection?.quantity;
          const total = price
            ? {
              ...price,
              amount : price.amount * qty,
              calculatedValue : price.calculatedValue * qty,
            }
            : null
          return (
            <TableCell
              key={key}
              width={settings.dimensions.xsmall}
              faded={greyOut}
            >
              { total ? formatCurrency(total) : '' }
            </TableCell>
          );
        case SELECTION_TABLE_KEYS.actions : return (
          <React.Fragment key={key}>
            { selection && generateActions?.(selection) }
          </React.Fragment>
        )
        default: return <TableCell key={key}/>;
      }
    });
  }, [
    disabled,
    selection,
    tableKeys,
    mode,
    product,
    fulfilment,
    assembly,
    assemblies,
    products,
    handleAssembly,
    handleProduct,
    handleQuantity,
    generateActions,
    calculateSelectionPrice,
    handleUpdateFulfilmentOnBlur,
    quantity
  ]);

  const [row, setRow] = useState<React.ReactNode[]>(rowGenerator());

  useEffect(() => {
    const lineItemProduct = listRecords(allProducts).find(
      product => product.id === lineItem.productId
    );
    setAssemblies(lineItemProduct
      ? getProductAssemblies(lineItemProduct)
      : [],
    )
  }, [lineItem, allProducts, getProductAssemblies]);

  useEffect(() => { setRow(rowGenerator()) }, [rowGenerator]);

  useEffect(() => {
    setQuantity(
      mode === 'fulfilment'
        ? (fulfilment?.fulfilledQty
          ?? fulfilment?.requestedQty
          ?? selection.quantity
          ?? null)
        : selection.quantity
    );
  }, [fulfilment, selection, mode]);

  return row;
}

export default SelectionRow;
