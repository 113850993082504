import React, { useCallback, useEffect, useState } from 'react';

import {
  LineItem,
  Fulfilment,
  ProjectedOrder,
  isFulfilmentStatus,
} from '#types';

import useMediaQuery from '#hooks/useMediaQuery';
import useNotifications from '#hooks/useNotifications';
import useCards from '#hooks/useCards';
import useOrders from '#hooks/useOrders';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Flex from '#materials/Flex';
import Icon from '#materials/Icon';
import Button from '#materials/Button';
import Banner from '#materials/Banner';
import Split from '#materials/Splt';
import ToggleButton, { ToggleButtonGroup } from '#materials/ToggleButton';

import OrderTotals from './OrderTotals';
import OrderForm, {
  TABLE_KEYS,
  SELECTION_TABLE_KEYS,
  defaultTableKeys,
  defaultSelectionTableKeys,
} from '#components/orders/OrderForm';

import { listRecords } from '#utils/data';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderFulfilment;
const localeNotificationKeys = locale.keys.notifications;
const localeButtonKeys = locale.keys.buttons;

const defaultFulfilmentTableKeys = [...defaultTableKeys].filter(
  (key) => key !== TABLE_KEYS.period
    && TABLE_KEYS.unit,
);
const tabletTableKeys = [...defaultFulfilmentTableKeys].filter(
  (key) => key !== TABLE_KEYS.productSku
    && key !== TABLE_KEYS.period
    && key !== TABLE_KEYS.requestedQty
    && key !== TABLE_KEYS.unit,
);
const selectionTabletTableKeys = [...defaultSelectionTableKeys].filter(
  (key) => key !== SELECTION_TABLE_KEYS.unit
);

interface OrderFulfilmentProps {
  order : ProjectedOrder;
}

const OrderFulfilment = ({ order } : OrderFulfilmentProps) => {
  const { isDesktop } = useMediaQuery();
  const { createNotification } = useNotifications();
  const { getCustomerCreditCards, load: loadCards } = useCards();
  const {
    refreshOrder,
    refreshLineItems,
    deleteFulfilment,
    bulkUpdateFulfilments,
    payOrders,
    createFulfilment,
    generateDefaultFulfilment,
  } = useOrders()
  const { refreshSelections } = useOptions()
  const {
    refreshSubscriptions,
    generateOrder,
  } = useSubscriptions()

  const [cardOnFile, setCardOnFile] = useState(false);
  const [confirmProcess, setConfirmProcess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [generating, setGenerating] = useState(false)

  const generated = order?.order
    && Object.keys(order.order?.fulfilments).length >= (
      Object.keys(order.lineItems).length + Object.keys(order.selections).length
    )
    && Object.values(order.lineItems).every((li) => (
      order.order
        && Object.values(order.order.fulfilments).some(f => (
          f.lineItemId === li.id && f.requestedProductId === li.productId
        ))
    ))
    && Object.values(order.selections).every((s) => (
      order.order
        && Object.values(order.order.fulfilments).some(f => (
          f.id && s.fulfilmentIds.includes(f.id)
        ))
    ));

  const handleRefresh = useCallback(async () => {
    setGenerating(true);
    await Promise.all([
      order.order?.id && refreshOrder(order.order?.id),
      refreshLineItems(),
      refreshSelections(),
      refreshSubscriptions(),
    ]);
    setGenerating(false);
  }, [
    order,
    refreshOrder,
    refreshLineItems,
    refreshSelections,
    refreshSubscriptions,
  ]);

  const handleInitProcess = useCallback(async () => {
    if (!order?.order) return;
    setConfirmProcess(true);
  }, [order]);

  const handleCancelProcess = useCallback(async () => {
    setConfirmProcess(false);
  }, []);

  const handleConfirmProcess = useCallback(async () => {
    let targetOrder = order.order;
    if (!generated) {
      setProcessing(true);
      const orders = await generateOrder(order, { defer : false });
      targetOrder = listRecords(orders)[0];
      if (!targetOrder) {
        createNotification({
          key : 'create-fulfilments-error',
          message : localize(localeNotificationKeys.fulfilments.generate.error),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
        setProcessing(false);
        return;
      }
    }

    if (!targetOrder) return;
    setProcessing(true);
    const paidOrders = await payOrders([targetOrder]);
    if (listRecords(paidOrders).length) {
      createNotification({
        key : 'process-order-success',
        message : localize(localeNotificationKeys.orders.process.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'process-order-error',
        message : localize(localeNotificationKeys.orders.process.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
    setProcessing(false);
    setConfirmProcess(false);
  }, [order, generated, generateOrder, payOrders, createNotification]);

  const handleCreateFulfilment = useCallback(
    async (lineItem : LineItem) => {
      const fulfilment = generateDefaultFulfilment({
        order,
        product : lineItem.productId,
      })
      if (!fulfilment) return;
      fulfilment.status = 'confirmed';
      fulfilment.fulfilledProductId = lineItem.productId;
      fulfilment.requestedQty = 0;
      fulfilment.fulfilledQty = lineItem.quantity;
      const newOrphanedFulfilment = await createFulfilment(fulfilment);

      if(newOrphanedFulfilment) {
        createNotification({
          key : 'create-fulfilment-success',
          message : localize(
            localeNotificationKeys.fulfilments.createOrphanedFulfilment.success
          ),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.primary,
        });
      } else {
        createNotification({
          key : 'create-fulfilment-error',
          message : localize(
            localeNotificationKeys.fulfilments.createOrphanedFulfilment.error
          ),
          icon : (<Icon icon={settings.svgIcons.receipt} />),
          colour : settings.colours.alert.alert,
        });
      }
  }, [order, createFulfilment, generateDefaultFulfilment, createNotification]);

  const handleUpdateFulfilment = useCallback(async (
    fulfilment : Fulfilment
  ) => {
    const newFulfilment = await bulkUpdateFulfilments([fulfilment]);
    if (newFulfilment) {
      createNotification({
        key : 'update-fulfilment-success',
        message : localize(localeNotificationKeys.fulfilments.update.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-fulfilment-error',
        message : localize(localeNotificationKeys.fulfilments.update.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [bulkUpdateFulfilments, createNotification])

  const handleDeleteFulfilment = useCallback((
    fulfilment : Fulfilment
  ) => async () => {
    const newFulfilment = await deleteFulfilment(fulfilment);
    if (newFulfilment) {
      createNotification({
        key : 'delete-fulfilment-success',
        message : localize(localeNotificationKeys.fulfilments.delete.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'delete-fulfilment-error',
        message : localize(localeNotificationKeys.fulfilments.delete.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [deleteFulfilment, createNotification]);

  const handleGenerate = useCallback(async () => {
    setGenerating(true);
    const success = !!(await generateOrder(order));
    setGenerating(false);

    if (success) {
      createNotification({
        key : 'create-fulfilments-success',
        message : localize(localeNotificationKeys.fulfilments.generate.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'create-fulfilments-error',
        message : localize(localeNotificationKeys.fulfilments.generate.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [order, generateOrder, createNotification]);

  const handleStatusChange = useCallback(async (status : string) => {
    if (!order?.order || !isFulfilmentStatus(status)) return;
    const fulfilments = Object.values(order.order.fulfilments);
    if (!fulfilments.length) return;

    const updates = await bulkUpdateFulfilments(
      fulfilments.map((fulfilment) => ({
        ...fulfilment,
        status,
      })
    ));
    if (updates) {
      createNotification({
        key : 'update-fulfilments-success',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.success,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'update-fulfilments-error',
        message : localize(
          localeNotificationKeys.fulfilments.bulkStatusUpdate.error,
        ),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [order, bulkUpdateFulfilments, createNotification]);

  useEffect(() => { loadCards() }, [loadCards]);

  useEffect(() => {
    if (order.paid || !order.customer) return;
    setCardOnFile(
      getCustomerCreditCards(order.customer).filter(
        cc => cc.expYear > new Date().getFullYear()
          || (cc.expYear === new Date().getFullYear()
            && cc.expMonth >= new Date().getMonth()),
      ).length > 0,
    );
  }, [order, getCustomerCreditCards]);

  const inProgress = [
    'inProgress',
    'ready',
    'fulfilled',
    'cancelled',
  ].includes(order.status ?? 'pending');

  return (
    <>
      { (!order.customer) && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.noCustomer)
            + ` ${order.guestCode}` }
        </Banner>
      ) }
      { (!order.paid && !cardOnFile && order.customer) && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          colour={settings.colours.alert.alert}
        >
          { localize(localeContentKeys.noCardOnFile) }
        </Banner>
      ) }
      { confirmProcess && (
        <Banner
          icon={(<Icon icon={settings.svgIcons.info} />)}
          actions={(
            <>
              <Button onClick={handleConfirmProcess}>
                { localize(localeButtonKeys.process) }
              </Button>
              <Button onClick={handleCancelProcess}>
                { localize(localeButtonKeys.cancel) }
              </Button>
            </>
          )}
          colour={settings.colours.alert.secondary}
        >
          { localize(localeContentKeys.confirmProcess) }
        </Banner>
      ) }
      <Split
        left={(
          <Button
            onClick={handleRefresh}
            disabled={generating || processing}
          >
            Refresh
          </Button>
        )}
        right={(
          <Flex
            direction={
              isDesktop
                ? settings.directions.row
                : settings.directions.column
            }
          >
            <Button
              disabled={
                !cardOnFile
                  || generating
                  || processing
                  || order?.paid
                  || (order.totals
                    .find(t => t.key === 'total')?.total.amount ?? 0) <= 0
              }
              onClick={handleInitProcess}
            >
              {
                order.paid
                  ? localize(localeContentKeys.paid)
                  : localize(localeContentKeys.processPayment)
              }
            </Button>
            { (generated || order?.paid)
              ? (
                <ToggleButtonGroup>
                  <ToggleButton
                    value="inProgress"
                    selected={order?.status === 'inProgress'}
                    onSelect={handleStatusChange}
                    first
                  >
                    In Progress
                  </ToggleButton>
                  <ToggleButton
                    value="ready"
                    selected={order?.status === 'ready'}
                    onSelect={handleStatusChange}
                  >
                    Ready
                  </ToggleButton>
                  <ToggleButton
                    value="fulfilled"
                    selected={order?.status === 'fulfilled'}
                    onSelect={handleStatusChange}
                    last
                  >
                    Complete
                  </ToggleButton>
                </ToggleButtonGroup>
              ) : (
                <Button
                  onClick={handleGenerate}
                >
                  Generate
                </Button>
              )
            }
          </Flex>
        )}
      />
      <OrderForm
        order={order}
        mode='fulfilment'
        disabled={order.paid || inProgress}
        onAddLineItem={handleCreateFulfilment}
        onUpdateFulfilment={handleUpdateFulfilment}
        onDeleteFulfilment={handleDeleteFulfilment}
        tableKeys={isDesktop ? defaultFulfilmentTableKeys : tabletTableKeys}
        selectionTableKeys={
          isDesktop
            ? defaultSelectionTableKeys
            : selectionTabletTableKeys
        }
      />
      <OrderTotals
        order={order}
        mode="fulfilment"
        disabled={order.paid || inProgress}
      />
    </>
  )
}

export default OrderFulfilment;
