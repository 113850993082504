import React, { useCallback, useEffect, useState } from 'react';

import { Subtotal, DraftOrder } from '#types';

import useNotifications from '#hooks/useNotifications';
import useAdjustments from '#hooks/useAdjustments';
import useOrders from '#hooks/useOrders';
import useTaxes from '#hooks/useTaxes';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import Table from '#materials/Table';
import {
  Action,
  TableCell,
  TableTextInputCell,
  TableActionCell,
} from '#materials/TableCell';

import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';

const localeContentKeys = locale.keys.content.orders.orderTotals;
const localeNotificationKeys = locale.keys.notifications.orders;
const localeTableKeys = locale.keys.tables.totals;

const TABLE_KEYS = {
  label : 'label' as const,
  amount : 'amount' as const,
  actions : 'actions' as const,
};
type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
const defaultTableKeys = Object.values(TABLE_KEYS);

const ROOT_LABLES = [
  'subtotal',
  'total',
];

interface OrderTotalsProps {
  order : DraftOrder;
  mode? : 'fulfilment' | 'edit';
  disabled? : boolean;
  tableKeys? : TableKey[];
}

function OrderTotals({
  order,
  mode,
  tableKeys = defaultTableKeys,
  disabled,
} : OrderTotalsProps) {
  const { taxes } = useTaxes();
  const { generateDefaultAdjustment } = useAdjustments();
  const { addAdjustmentToOrder, removeAdjustmentFromOrder } = useOrders();
  const { createNotification } = useNotifications();

  const [rows, setRows] = useState<React.ReactNode[]>([]);
  const [adjustmentName, setAdjustmentName] = useState('');
  const [adjustmentAmount, setAdjustmentAmount] = useState(0);

  const handAdjustmentNameChange = useCallback((name : string) => {
    setAdjustmentName(name);
  }, []);

  const handleAdjAmountChange = useCallback((value : number | null) => {
    setAdjustmentAmount(value || 0);
  }, []);

  const handleApplyAdjustment = useCallback(async () => {
    if(!order.order || !adjustmentAmount) return;

    const adjToApply = {
      ...generateDefaultAdjustment({ scope : 'order' }),
      name : adjustmentName,
      currency : {
        amount : Math.round(adjustmentAmount * 100),
        currencyCode : 'CAD',
        increment : 0.01,
        calculatedValue : adjustmentAmount,
      },
    }

    const newOrder = await addAdjustmentToOrder(order.order, adjToApply);

    if (newOrder) {
      setAdjustmentAmount(0);
      setAdjustmentName('');
      createNotification({
        key : 'add-adjustment-success',
        message : localize(
          localeNotificationKeys.applyAdjustment.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'add-adjustment-error',
        message : localize(
          localeNotificationKeys.applyAdjustment.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [
    order,
    adjustmentName,
    adjustmentAmount,
    generateDefaultAdjustment,
    addAdjustmentToOrder,
    createNotification
  ]);

  const handleDeleteManualAdjustment = useCallback(async (
    appliedAdjustmentIds : number[] | undefined
  ) => {
    if(!appliedAdjustmentIds || !order.order) return;

    const match = Object.values(order.order.appliedAdjustments).find((adj) => {
      return adj.id === appliedAdjustmentIds[0];
    });
    if(!match) return;

    const result = await removeAdjustmentFromOrder(order.order, match);

    if (result) {
      createNotification({
        key : 'remove-adjustment-success',
        message : localize(
          localeNotificationKeys.removeAdjustment.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      createNotification({
        key : 'remove-adjustment-error',
        message : localize(
          localeNotificationKeys.removeAdjustment.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [order, removeAdjustmentFromOrder, createNotification]);

  const generateRows = useCallback(() => {
    function generate(subtotal : Subtotal, key : TableKey) {
      switch (key) {
        case TABLE_KEYS.label:
          const tax = subtotal.taxId ? taxes?.[subtotal.taxId] : null;
          const localized = (localeContentKeys as any)[subtotal.key]
            ? localize((localeContentKeys as any)[subtotal.key])
            : '';
          return (<TableCell key={key}>
            {
              subtotal.label
                ?? (ROOT_LABLES.includes(subtotal.key) ? '' : '\t') + (tax
                  ? tax.name
                  : localized)
            }
          </TableCell>);
        case TABLE_KEYS.amount:
          return (
            <TableCell
              key={key}
              width={settings.dimensions.xsmall}
              alignment={settings.alignments.right}
            >
              { formatCurrency(subtotal.total) }
            </TableCell>
          );
        case TABLE_KEYS.actions:
          return (
            !disabled
              && mode === 'fulfilment'
              && !order.paid
              && subtotal.key !== 'subtotal'
              && subtotal.key !== 'total'
              && subtotal.key !== 'paid'
              && subtotal.key !== 'balance'
              && subtotal.taxId === undefined
          ) ? (
            <TableActionCell>
              <Action
                label={localize(localeTableKeys.actions.removeAdjustment)}
                onClick={() => {
                  handleDeleteManualAdjustment(subtotal.appliedAdjustmentIds);
                }}
              >
                <Icon
                  icon={settings.svgIcons.clear}
                  colour={settings.colours.button.alert}
                />
              </Action>
            </TableActionCell>
          ) : null
        default:
          return <TableCell key={key} />;
      }
    }

    const adjustmentFields = (
      !disabled
        && mode === 'fulfilment'
        && !order.paid
    ) && (
      <>
        { tableKeys.map((key) => {
          switch (key) {
            case TABLE_KEYS.label:
              return (
                <TableTextInputCell
                  key={key}
                  id={`${order.order?.id}-manual-adjustment-label`}
                  value={adjustmentName}
                  onChange={handAdjustmentNameChange}
                  disabled={disabled}
                />
              );
            case TABLE_KEYS.amount:
              return (
                <TableTextInputCell
                  key={key}
                  id={`${order.order?.id}-manual-adjustment-amount`}
                  value={adjustmentAmount}
                  onChange={handleAdjAmountChange}
                  inputType={settings.inputType.number}
                  inputFormat={settings.inputFormat.currency}
                  alignment={settings.alignments.right}
                />
              );
            case TABLE_KEYS.actions:
              return (
                <TableActionCell key={key}>
                  <Action
                    label={localize(localeTableKeys.actions.applyAdjustment)}
                    disabled={!adjustmentAmount || !adjustmentName}
                    onClick={handleApplyAdjustment}
                  >
                    <Icon icon={settings.svgIcons.check} />
                  </Action>
                </TableActionCell>
              );
            default:
              return (<TableCell key={key} />);
          }
        }) }
      </>
    );

    const rows = order.totals.map((subtotal) => (
      <React.Fragment key={subtotal.key}>
        { tableKeys.map((key) => generate(subtotal, key)) }
      </React.Fragment>
    ))
    const subtotalIndex = rows.findIndex((row) => row.key === 'subtotal');

    setRows([
      ...rows.slice(0, subtotalIndex + 1),
      adjustmentFields,
      ...rows.slice(subtotalIndex + 1),
    ]);
  }, [
    order,
    mode,
    disabled,
    tableKeys,
    taxes,
    adjustmentAmount,
    adjustmentName,
    handleAdjAmountChange,
    handleApplyAdjustment,
    handleDeleteManualAdjustment,
    handAdjustmentNameChange,
  ]);

  useEffect(() => { generateRows(); }, [generateRows]);

  return (
    <Table
      head={null}
      rows={rows}
      width={settings.dimensions.half}
      alignment={settings.alignments.right}
    />
  );
}

export default OrderTotals;
